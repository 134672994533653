import {
  BulkRequest,
  BulkResponse,
  ServicesCatalogServer,
  GetActiveFeaturesResponse,
} from '@wix/ambassador-services-catalog-server/http';
import { BookingsServer } from '@wix/ambassador-bookings-server/http';
import { CheckoutServer } from '@wix/ambassador-checkout-server/http';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import {
  mapCatalogResourceResponseToStaffMember,
  mapResponseToBusinessInfo,
} from '@wix/bookings-uou-mappers';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { CatalogData } from './types';
import {
  mapCatalogServiceToService,
  Service,
} from '../utils/mappers/service.mapper';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';

export class BookingsApi {
  private authorization: string;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private checkoutServer: ReturnType<typeof CheckoutServer>;

  constructor({
    authorization,
    baseUrl,
  }: {
    authorization: string;
    baseUrl: string;
  }) {
    this.authorization = authorization;
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.checkoutServer = CheckoutServer(`${baseUrl}${CHECKOUT_SERVER_URL}`);
  }

  async getCatalogData({
    serviceId,
    resourceId,
  }: {
    serviceId: string;
    resourceId: string;
  }): Promise<CatalogData> {
    const servicesCatalogService = this.catalogServer.Bulk();
    const bulkRequest: BulkRequest = this.createBulkRequest({
      serviceId,
      resourceId,
    });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const service: Service = mapCatalogServiceToService(
      catalogData.responseService!,
    );
    const businessInfo: BusinessInfoBase = mapResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );
    const activeFeatures: GetActiveFeaturesResponse =
      catalogData.responseBusiness!.activeFeatures!;
    const staffMember = mapCatalogResourceResponseToStaffMember(
      catalogData.responseResource!,
    );

    return {
      service,
      businessInfo,
      activeFeatures,
      staffMember,
    };
  }

  async getPricingPlanDetails({
    serviceId,
    startTime,
  }: {
    serviceId: string;
    startTime: string;
  }): Promise<Optional<PaidPlans>> {
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.authorization })
      .checkoutOptions({
        createSession: {
          scheduleOwnerId: serviceId,
          start: {
            timestamp: startTime,
          },
        },
        paymentSelection: {
          numberOfParticipants: 1,
        },
      });

    return response.checkoutOptions?.paidPlans;
  }

  private createBulkRequest({
    serviceId,
    resourceId,
  }: {
    serviceId: string;
    resourceId: string;
  }): BulkRequest {
    return {
      requestService: {
        id: serviceId,
        fields: [],
      },
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestGetResource: {
        id: resourceId,
        fields: [],
      },
    };
  }
}
