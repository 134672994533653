import { BusinessInfoBase } from '@wix/bookings-uou-types';
import {
  GetActiveFeaturesResponse,
} from '@wix/ambassador-services-catalog-server/http';

import { CatalogData } from '../../api/types';
import { Service } from '../mappers/service.mapper';

export type FormContext = {
  service?: Service;
  isPricingPlanInstalled?: boolean;
  hasCoupon?: boolean;
  businessInfo?: BusinessInfoBase;
  activeFeatures?: GetActiveFeaturesResponse;
};

export const createFormContext = ({
  catalogData,
  isPricingPlanInstalled,
}: {
  catalogData: CatalogData;
  isPricingPlanInstalled: boolean;
}): FormContext => {
  return {
    activeFeatures: catalogData.activeFeatures,
    service: catalogData.service,
    businessInfo: catalogData.businessInfo,
    isPricingPlanInstalled,
  };
};
