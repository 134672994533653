import { TimezoneType } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Service } from '../../utils/mappers/service.mapper';
import { CatalogData } from '../types';

export function createDummyCatalogData(
  flowAPI: ControllerFlowAPI,
): CatalogData {
  const { t, config } = flowAPI.translations;
  const service: Service = {};

  const businessInfo = {
    language: config.language,
    currency: t('dummy-content.business.currency'),
    timeZone: t('dummy-content.business.timeZone'),
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: t('dummy-content.business.regionalSettingsLocale'),
  };

  const activeFeatures = {
    applicableForCourse: true,
    bookingsStaffLimit: 1,
    applicableForServiceList: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForSmsReminders: true,
  };
  const staffMember = {
    id: 'staff-member-1',
    name: 'name',
  };

  return {
    service,
    businessInfo,
    activeFeatures,
    staffMember,
  };
}
