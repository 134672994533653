import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { BookingsApi } from './BookingsApi';
import { CatalogData } from './types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { createDummyCatalogData } from './DummyData/dummyCatalogData';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface FormApiInitParam {
  wixSdkAdapter: WixOOISDKAdapter;
  flowAPI: ControllerFlowAPI;
}
export const MEMBERS_SERVER_API = '_api/members/v1/members';

export class FormApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;
  private readonly authorization: string;
  private membersServer: ReturnType<typeof MembersNgApi>;
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ wixSdkAdapter, flowAPI }: FormApiInitParam) {
    this.flowAPI = flowAPI;
    const baseUrl = wixSdkAdapter.getServerBaseUrl();
    this.authorization = wixSdkAdapter.getInstance();
    this.wixSdkAdapter = wixSdkAdapter;
    this.bookingsApi = new BookingsApi({
      authorization: this.authorization,
      baseUrl,
    });
    this.membersServer = MembersNgApi(`${baseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
  }

  async getCatalogData({
    serviceId,
    resourceId,
  }: {
    serviceId: string;
    resourceId: string;
  }): Promise<CatalogData> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyCatalogData(this.flowAPI);
    }
    return this.bookingsApi.getCatalogData({ serviceId, resourceId });
  }

  async getMemberDetails(): Promise<Optional<Member>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return;
    }
    const membersService = this.membersServer.Members();
    const { member } = await membersService({
      Authorization: this.authorization,
    }).getMyMember({
      fieldSet: 'FULL',
    });

    return member;
  }

  async getPricingPlanDetails({
    serviceId,
    startTime,
  }: {
    serviceId: string;
    startTime: string;
  }): Promise<Optional<PaidPlans>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return;
    }
    return this.bookingsApi.getPricingPlanDetails({ serviceId, startTime });
  }
}
